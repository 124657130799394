import PureBreadcrumbs from "../breadcrums";
import React, { useEffect } from "react"
import { Container, Row, Col, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { fetchMeterValueById } from "../../../store/actions/reportActions";
import { useTranslation } from "react-i18next";
import ReactJson from 'react-json-view';

function SingleTransactionView(props) {
    const { charging_id } = useParams()
    const { t } = useTranslation();

    useEffect(() => {
        props.fetchData(charging_id)
    }, []);

    const data = props.fetch && props.fetch.log ?  props.fetch.log :{}
    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col className="mt-4">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}> {t("Transaction Details")} </span>
                </Col>
            </Row>
            <Card className="tariffcard mt-4">
            <Row >
                <Col>
                <ReactJson src={data} theme="monokai" />
                </Col>
            </Row>
            </Card>
         

        </Container>
    )

}
const mapStateToProps = function (state) {
    return {
        fetch: state.report.fetchMeterDataByID
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchData: (charging_id) => dispatch(fetchMeterValueById(charging_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SingleTransactionView));