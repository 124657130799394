import React from 'react'
import { useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from 'react-redux';
import './ticketManagement.css';
import { fetchAllTicket } from '../../../store/actions/ticketAction';
import { AiFillClockCircle } from 'react-icons/ai';
import PureBreadcrumbs from "../breadcrums";
import "./ticketManagement.css"
import moment from "moment";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useTranslation } from 'react-i18next';

function TicketView(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.fetchAll()
  }, [])

  const data = props.fetchAllTicket && props.fetchAllTicket.data;
  console.log(data);
  const openList = props.fetchAllTicket && props.fetchAllTicket.data && props.fetchAllTicket.data.filter(en => en.status === "Open")
  const inprogressList = props.fetchAllTicket && props.fetchAllTicket.data && props.fetchAllTicket.data.filter(en => en.status === "In Progress")
  const closedList = props.fetchAllTicket && props.fetchAllTicket.data && props.fetchAllTicket.data.filter(en => en.status === "Closed")
  // console.log(data)
  return (

    <Container fluid >
      <PureBreadcrumbs />
      <br />
      <Row>
        <Col sm={12} md={12} lg={4}>
          <Card className="tariffcard" style={{ borderRadius: "5px", borderColor: "transparent" }}>
            <div className="table-header d-flex justify-content-between p-2">
              <b> {t("Open")}</b>
              <div className="dot text-center">
                {/* {openList !== undefined ? openList.length : 0} */}
                {openList && openList.length > 0 ? openList.length : 0}
              </div>
            </div>
            <div >

              {openList && openList.length > 0 ? <>
                {openList && openList.map((items, index) => {
                  const time = moment(items.createdat).local().format("DD-MM-YYYY");
                  function getBackgroundColor(status) {
                    const backgroundColorByStatus = {
                      Low: 'rgb(47, 183,41, 100)',
                      Medium: 'rgb(24, 122, 246,85)',
                      High: 'rgb(238, 160,5, 100)',
                      Critical: 'rgb(235,6,41,86)',
                    }
                    return backgroundColorByStatus[status]
                  }
                  return (
                    <div key={index} className='innerticketlist'>
                      <Card className='ticket_inner_card'>
                        <div className="d-flex justify-content-between">
                          <h6># {items.ticket_id} </h6>
                          <div>
                            <AiFillClockCircle /> {time}
                          </div>
                        </div>
                        <div className='mt-2'> {items.title}</div>
                        <div className="d-flex justify-content-between">
                          <div className="severitydot text-center mt-2" style={{ backgroundColor: getBackgroundColor(items.severity) }}>
                            {items.severity}
                          </div>
                          <div className="text-center mt-2">
                            {items.status}
                          </div>
                          <div className="btn-group dropdown">
                            <button
                              className="btn btn-md"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="" title="Comparision Graph">
                                <BsThreeDotsVertical />
                              </i>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"

                            >
                              <a className="dropdown-item" href={`/dashboard/singleViewTicket/${items.ticket_id}`}>
                                {t("View")}
                              </a>
                              <a className="dropdown-item" href={`/dashboard/updateTicket/${items.ticket_id}`}>
                                {t("Edit")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )
                })}
              </>
                : <> <h4 className='text-center m-4'>{t("No data found")}</h4></>}
            </div>
          </Card>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <Card className="tariffcard" style={{ borderRadius: "5px", borderColor: "transparent", minHeight: "75vh" }}>
            <div className="table-header d-flex justify-content-between  p-2">
              <b> {t("In Progress")}</b>
              <div className="dot text-center">
                {/* {inprogressList !== undefined ? inprogressList.length : 0} */}
                {inprogressList && inprogressList.length > 0 ? inprogressList.length : 0}
              </div>
            </div>
            {inprogressList && inprogressList.length > 0 ? <>
              {inprogressList && inprogressList.map((items, index) => {
                const time = moment(items.createdat).local().format("DD-MM-YYYY");
                function getBackgroundColor(status) {
                  const backgroundColorByStatus = {
                    Low: 'rgb(47, 183,41, 100)',
                    Medium: 'rgb(24, 122, 246,85)',
                    High: 'rgb(238, 160,5, 100)',
                    Critical: 'rgb(235,6,41,86)',
                  }
                  return backgroundColorByStatus[status]
                }
                return (
                  <div key={index} className='innerticketlist'>
                    <Card className='ticket_inner_card'>
                      <div className="d-flex justify-content-between">
                        <h6># {items.ticket_id} </h6>
                        <div>
                          <AiFillClockCircle /> {time}
                        </div>
                      </div>
                      <div className='mt-2'> {items.title}</div>
                      <div className="d-flex justify-content-between">
                        <div className="severitydot text-center mt-2" style={{ backgroundColor: getBackgroundColor(items.severity) }}>
                          {items.severity}
                        </div>
                        <div className="text-center mt-2">
                          {items.status}
                        </div>
                        <div className="btn-group dropdown">
                          <button
                            className="btn btn-md"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="" title="Comparision Graph">
                              <BsThreeDotsVertical />
                            </i>
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a className="dropdown-item" href={`/dashboard/singleViewTicket/${items.ticket_id}`}>
                              {t("View")}
                            </a>
                            <a className="dropdown-item" href={`/dashboard/updateTicket/${items.ticket_id}`}>
                              {t("Edit")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                )
              })}
            </>
              : <> <h4 className='text-center m-4'> No Data </h4></>}
          </Card>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <Card className="tariffcard" style={{ borderRadius: "5px", borderColor: "transparent", minHeight: "75vh" }}>
            <div className="table-header d-flex justify-content-between  p-2">
              <b> {t("Closed/Resolved")}</b>
              <div className="dot text-center">
                {/* {closedList !== undefined ? closedList.length : 0} */}
                {inprogressList && inprogressList.length > 0 ? inprogressList.length : 0}

              </div>
            </div>
            {closedList && closedList.length > 0 ? <>
              {closedList && closedList.map((items, index) => {
                const time = moment(items.createdat).local().format("DD-MM-YYYY");
                function getBackgroundColor(status) {
                  const backgroundColorByStatus = {
                    Low: 'rgb(47, 183,41, 100)',
                    Medium: 'rgb(24, 122, 246,85)',
                    High: 'rgb(238, 160,5, 100)',
                    Critical: 'rgb(235,6,41,86)',
                  }
                  return backgroundColorByStatus[status]
                }
                return (
                  <div key={index} className='innerticketlist'>
                    <Card className='ticket_inner_card'>
                      <div className="d-flex justify-content-between">
                        <h6># {items.ticket_id} </h6>
                        <div>
                          <AiFillClockCircle /> {time}
                        </div>
                      </div>
                      <div className='mt-2'> {items.title}</div>
                      <div className="d-flex justify-content-between">
                        <div className="severitydot text-center mt-2" style={{ backgroundColor: getBackgroundColor(items.severity) }}>
                          {items.severity}
                        </div>
                        <div className="text-center mt-2">
                          {items.status}
                        </div>
                        <div className="btn-group dropleft">
                          <button
                            className="btn btn-md"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="" title="Comparision Graph">
                              <BsThreeDotsVertical />
                            </i>
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a className="dropdown-item" href={`/dashboard/singleViewTicket/${items.ticket_id}`}>
                              {t("View")}
                            </a>
                            <a className="dropdown-item" href={`/dashboard/updateTicket/${items.ticket_id}`}>
                              {t("Edit")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                )
              })}
            </>
              : <> <h4 className='text-center m-4'> No Data </h4></>}
          </Card>
        </Col>
      </Row>
      <br />
    </Container >

  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.ticket.isLoading,
    fetchAllTicket: state.ticket.fetch_all_ticket,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchAll: () => dispatch(fetchAllTicket()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TicketView);