import React, { useState } from "react";
import "./toolbar.css";
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { GrArchlinux } from "react-icons/gr";

function MainSideToolBar(props) {
    const { t } = useTranslation();
    const { access } = JSON.parse(localStorage.getItem("user"));
    const [activeNestedIndex, setActiveNestedIndex] = useState(null);

    const headerSet = new Set();
    const headerArray = [];
    // console.log(access)

    const parentKeyToIdMap = {
        'insight': 1,
        'livecharging': 2,
        'customer': 3,
        'tariff': 4,
        'evse': 5,
        'remote': 6,
        'ocpi_emsp': 7,
        'ocpi_cpo': 8,
        'booking': 9,
        'loadmanagement': 10,
        'organisation': 11,
        'report': 12,
        'fleet': 13,
        'advertisement': 14,
        'campaign': 15,
        'notification': 16,
        'log': 17,
        'finance': 18,
        'admin': 19,
        'ticket': 20,
        'rule': 21,
        'program': 22,
        'redeem': 23,
        'customer_redemption': 24
    };

    const modifiedAccess = access && access.map(item => ({
        ...item,
        id: parentKeyToIdMap[item.parent_key] || null
    }));
    const newAccess = modifiedAccess && modifiedAccess.sort((a, b) => a.id - b.id);
    // console.log("newAccess: ", newAccess)

    newAccess && newAccess.map(item => {
        if (!headerSet.has(item.header)) {
            headerSet.add(item.header);
            headerArray.push(item.header);
        }
    });
    // console.log("headerArray", headerArray);
    const iconMapping = {
        'CPO': <> <img src={process.env.PUBLIC_URL + '/images/sidebaricons/CPO.svg'} alt="CPO icon" title="Charge point operator" style={{ width: '20px', height: '20px' }} /></>,
        "EMSP": <> <img src={process.env.PUBLIC_URL + '/images/sidebaricons/emsp.svg'} alt="emsp icon" title="E-mobility service provider" style={{ width: '20px', height: '20px' }} /></>,
        "OCPI": <> <GrArchlinux alt="ocpi icon" title="Open Charge Point Protocol" style={{ width: '20px', height: '20px' }} /></>,
        'ENERGY': <> <img src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-energy.svg'} alt="energy icon" style={{ width: '20px', height: '20px' }} /> </>,
        'PARTNER': <> <img src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-partner.svg'} alt="partner icon" style={{ width: '20px', height: '20px' }} /> </>,
        'ALERT': <> <img src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-notification.svg'} alt="notification icon" style={{ width: '20px', height: '20px' }} /></>,
        'FLEET': <> <img src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-fleet.svg'} alt="fleet icon" style={{ width: '20px', height: '20px' }} /></>,
        'CAMPAIGN': <> <img src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-campaign.svg'} alt="campaign icon" style={{ width: '20px', height: '20px' }} /></>,
        'ADMIN': <> <img src={process.env.PUBLIC_URL + '/images/sidebaricons/sidebar-admin.svg'} alt="admin icon" style={{ width: '20px', height: '20px' }} /></>,
        'LOYALTY': <> <img src={process.env.PUBLIC_URL + '/images/sidebaricons/loyalty_black.svg'} alt="admin icon" style={{ width: '20px', height: '20px' }} /></>,
    };

    const headerFiltered = headerArray.map((value, index) => ({ header: value, icon: iconMapping[value] || null }));
    // console.log("headerFiltered", headerFiltered);
    const filteredHeaderArrayOfObjects = headerFiltered.filter(item => item.header !== "");

    // console.log("filteredHeaderArrayOfObjects", filteredHeaderArrayOfObjects);

    const handleNestedCollapseToggle = (index) => {
        setActiveNestedIndex((prevIndex) =>
            prevIndex === index ? null : index
        );
    };
    const [show, setShow] = React.useState(false);
    const toggleShow = () => setShow((prev) => !prev);
    // console.log("show", show);

    return (
        <nav className="mobile-sidebar">
            <div className="side-bar-inside">

                {filteredHeaderArrayOfObjects &&
                    filteredHeaderArrayOfObjects.map((tab, index) => (
                        <div className="mb-2 in-menu-dropdown" key={index}>
                            {['right'].map((direction) => (
                                <DropdownButton
                                    key={index}
                                    className="dropdown-img"
                                    drop={direction}
                                    title={
                                        <div className="sidebtn">
                                            <div className="label-title-mobile" style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                marginRight: '4px',
                                            }}>
                                                <span className={`header-icon mb-1`}>
                                                    {tab.icon}
                                                </span>
                                                <div className="small-tab-header">{t(`${tab.header}`)}</div>
                                            </div>
                                        </div>
                                    }
                                // menuAlign="right" 
                                >

                                    <Dropdown className={`dropdown-item `} show={show} onToggle={(isOpen) => setShow(isOpen)} >
                                        <ul className="list-unstyled components mb-0">
                                            <div className="triangle-left"></div>

                                            {newAccess && newAccess.filter(item => item.header === tab.header)
                                                .map((item, index) =>
                                                    <li
                                                        key={item.key || index}
                                                        style={{ marginBottom: '5px', marginTop: '7px', marginLeft: '10px' }}

                                                        className={
                                                            ("btn-sidebar ", activeNestedIndex === index ? "active" : "")
                                                        }
                                                    >
                                                        <a
                                                            href={item.key}
                                                            onClick={() => handleNestedCollapseToggle(index)}
                                                            style={{ textDecoration: 'none', color: '#000', cursor: 'pointer' }}
                                                            className={` title ${activeNestedIndex === index ? "active" : ""
                                                                }`}
                                                        >
                                                            <span className='' style={{ display: 'flex' }}>
                                                                <img
                                                                    className="sidebar-icon"
                                                                    src={`${process.env.PUBLIC_URL}/images/sidebaricons/${item.icon}`}
                                                                    style={{ width: "25px",height: "23px" }}
                                                                    alt="X"
                                                                />
                                                                {t(`${item.value}`)}

                                                                <span className="ml-auto submenu-icon pl-3">
                                                                    {activeNestedIndex === index ? (
                                                                        <FaAngleDown />
                                                                    ) : (
                                                                        <FaAngleRight />
                                                                    )}
                                                                </span>
                                                            </span>
                                                        </a>
                                                        {item.menu &&
                                                            item.menu.map((key, subIndex) => (
                                                                <ul
                                                                    key={key.key || subIndex}
                                                                    className={`collapse item-collapse list-unstyled subtitle ${activeNestedIndex === index ? "show" : ""
                                                                        }`}
                                                                    id={`collapse${index}`}

                                                                >
                                                                    <li className="inner-item" onClick={toggleShow} >
                                                                        <Link to={`${props.match.url}/${key.key}`} style={{ textDecoration: 'none', color: '#000', cursor: 'pointer' }}>
                                                                            <Dropdown.Item href={`${props.match.url}/${key.key}`}>
                                                                                <span className="circle-dot"></span>
                                                                                {t(`${key.value}`)}
                                                                            </Dropdown.Item>
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            ))}
                                                    </li>
                                                )}
                                        </ul>
                                    </Dropdown>

                                </DropdownButton>
                            ))}
                        </div>
                    ))}

            </div>
        </nav>
    );
}

export default MainSideToolBar;
