import React from 'react'
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { allEvseId, fetSubNetworkAction, updateStationV2EvseAction } from "../../../../store/actions/evseAction";
import { fetchActiveTariffList } from "../../../../store/actions/tariffAction";
import "../evse.css";
import PureBreadcrumbs from "../../breadcrums";
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';

const UpdateStationv2Component = (props) => {
    const { t } = useTranslation();
    const [formStep, setFormStep] = useState(0)
    const [address, setAddress] = useState("")
    const [stationid, setStationId] = useState("")
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [chargersValid, setChargersValid] = useState('');
    const [filterConnectorData, setFilterConnectorData] = useState([])
    const [triggerEffect, setTriggerEffect] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [countryCode, setCountryCode] = useState("");

    const evseSchema = Yup.object().shape({
        location: Yup.string(),
        address: Yup.string().required('Address is required'),
        city: Yup.string().required('City  is required'),
        state: Yup.string().required('State/Province  is required'),
        country: Yup.string().required('Country is required'),
        Zipcode: Yup.string().required('Zip code is a required').typeError('Zip code can only be a number'),
        latitude: Yup.number().required('Latitude is a required').typeError('Latitude can only be a number'),
        longitude: Yup.number().required('Longitude is a required').typeError('Longitude can only be a number'),
        operator: Yup.string().required('Organisation is required'),
        network: Yup.string().required('Network is required'),
        category: Yup.string().required('Sub-Network Category is required'),
        parkingtype: Yup.string().required('Parking Type is required'),
        free_charging: Yup.boolean().required('Free Charging field is required'),
        ocpi: Yup.bool().required('OCPI is required').typeError('Please select a valid value for OCPI'),
        qrcode: Yup.string(),
        description: Yup.string(),
        amenities: Yup.array().of(
            Yup.object().shape({
                amenity: Yup.bool().oneOf([true], 'Please select atleast one aminity'),
            })),

        evses: Yup.array().of(
            Yup.object().shape({
                evse_id: Yup.string().required('EVSE ID is required'),
                charger_name: Yup.string().required('Charger Name is required'),
                ocpp: Yup.string().required('OCPP Version is required'),
            })),

        connectors: Yup.array().of(
            Yup.object().shape({
                evse_id: Yup.string().required('EVSE ID is required'),
                id: Yup.string().required('Connector ID is required'),
                standard: Yup.string().required('Connector Type is required'),
                speed: Yup.string().required('Connector Charging Speed is required'),
                format: Yup.string().required('Connector Power is required'),
                power_type: Yup.string().required('Connector Power Type is required'),
            })),

        prices: Yup.array().of(
            Yup.object().shape({
                price: Yup.string().required('Price Name  is required'),
                status: Yup.string().required('Status is required'),
                publish: Yup.bool().required('Publish is required'),
            })),
    });

    const { register, control, setValue, getValues, watch, handleSubmit, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(evseSchema), mode: "all", defaultValues: {
            location: '', city: '', state: '', country: '', Zipcode: '', description: '', address: '', latitude: '', longitude: '', network: '', category: '', operator: '',
        }
    });
    const { fields: fieldsList1, remove: removeList1, append: appemdList1 } = useFieldArray({ control, name: "evses" });
    const { fields: fieldsList2, remove: removeList2, append: appemdList2 } = useFieldArray({ control, name: "connectors" });
    const { fields: fieldsList3 } = useFieldArray({ control, name: "prices" });
    const evseArray = watch("evses");
    const connectorArray = watch("connectors");
    const prices = watch("prices");
    const data = props.fetchstation && props.fetchstation;
    const Evse_id = evseArray && evseArray.map((evse) => evse.evse_id)
    // console.log("data", data)
    // console.log("errors", errors);
    // console.log("connectorArray", connectorArray);

    useEffect(() => {
        if (connectorArray && Evse_id) {
            const chargersWithConnectors = Evse_id.every(id =>
                connectorArray.some(connector => connector.evse_id === id)
            );
            // console.table(chargersWithConnectors)
            setChargersValid(chargersWithConnectors);
        }
    }, [connectorArray, Evse_id, formStep]);


    useEffect(() => {
        const { id, created_by, location, city, state, free_charging, description, country, address, latitude, location_sub_type, location_type, longitude, operator, organisation, parking_type, postal_code, connectors, amenities, evses, prices, role, createdat, ocpi, qrcode } = data
        setStationId(id)
        if (data) {
            // console.log("data of edit", data)
            setTimeout(() => {
                setValue("location", location, { shouldValidate: true });
                setValue("city", city, { shouldValidate: true });
                setValue("state", state, { shouldValidate: true });
                setValue("country", country, { shouldValidate: true });
                setValue("Zipcode", postal_code, { shouldValidate: true });
                setValue("address", address, { shouldValidate: true });
                setValue("latitude", latitude, { shouldValidate: true });
                setValue("longitude", longitude, { shouldValidate: true });
                setValue("network", location_type, { shouldValidate: true });
                setValue("operator", operator, { shouldValidate: true });
                setValue("parkingtype", parking_type, { shouldValidate: true });
                setValue("evses", evses, { shouldValidate: true });
                setValue("connectors", connectors, { shouldValidate: true });
                setValue("organisation", organisation, { shouldValidate: true });
                setValue("free_charging", free_charging, { shouldValidate: true });
                setValue("role", role, { shouldValidate: true });
                setValue("created_by", created_by, { shouldValidate: true });
                setValue("ocpi", ocpi, { shouldValidate: true });
                setValue("description", description, { shouldValidate: true });
                if (qrcode === "") {
                    let result = generateSixDigitNumber();
                    setValue("qrcode", result, { shouldValidate: true });
                } else {
                    setValue("qrcode", qrcode, { shouldValidate: true });
                }
                props.getSubNetwork(location_type);
                setValue("category", location_sub_type, { shouldValidate: true });
                register('organisation', organisation)
                register('role', role)
                register('created_by', created_by)
                setAddress(address)
                register('address', address)
                setValue("createdat", createdat)
                register('createdat', createdat)
                setValue("prices", prices, { shouldValidate: true });
                setSelectedAmenities(amenities)

            }, 1500);
        }
    }, [data], setValue)

    // this logic is to remove that particular connector data from the array which evse_id is being deleted 

    useEffect(() => {
        const FilteredData = () => {
            const matchedItems = [];

            connectorArray && connectorArray.forEach(item => {
                if (Evse_id && Evse_id.includes(item.evse_id)) {
                    matchedItems.push(item);
                }
            });
            setFilterConnectorData(matchedItems)
        }
        FilteredData();
        if (formStep === 2) {
            setValue("connectors", filterConnectorData);
        }
    }, [formStep, triggerEffect])

    // console.log("FilteredConnectorData", filterConnectorData);

    // logic end

    const generateSixDigitNumber = () => {
        const length = 6;
        const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }
        return result;
    };

    useEffect(() => {
        props.getActiveTariffList();
    }, [])


    const handleSelectChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setSelectedAmenities((prevSelectedAmenities) => [...prevSelectedAmenities, value]);
        } else {
            setSelectedAmenities((prevSelectedAmenities) =>
                prevSelectedAmenities.filter((amenity) => amenity !== value)
            );
        }
    };
    // console.log("country code", countryCode)
    const onSubmit = async data => {
        // console.log(data,stationid,selectedAmenities)
        props.updateEvse(data, stationid, selectedAmenities, countryCode)
    };

    useEffect(() => {
        if (props.upstatus.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Station has been Updated successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.upstatus]);

    if (redirect) {
        return <Redirect to='/dashboard/view_evse' />;
    }


    const handleEvseIdBlur = () => {
        const evseArrayID = evseArray;
        const evseID = evseArrayID && evseArrayID.map((ev) => ev.evse_id);
        props.fetchAllEvseId(evseID);

    };

    const completeFormStep = () => {
        setFormStep(cur => cur + 1)
    }
    const goToPrevStep = () => {
        setFormStep(cur => cur - 1)
    }
    const handleAddressChange = address => {
        setAddress(address);
    };

    // GeoLocation logic
    const handleSelect = address => {
        geocodeByAddress(address).then(results => getLatLng(results[0])).then(latLng => {
            const { lat, lng } = latLng
            const res = latiLongi(lat, lng)
            res.then(respon => {
                // const address = respon.results[0].formatted_address;
                setAddress(address)
                setValue("address", address);
                setValue("latitude", lat);
                setValue("longitude", lng);
                let city, state, country, postCode, countryCode;
                for (let i = 0; i < respon.results[0].address_components.length; i++) {
                    for (let j = 0; j < respon.results[0].address_components[i].types.length; j++) {
                        switch (respon.results[0].address_components[i].types[j]) {
                            case "locality":
                                city = respon.results[0].address_components[i].long_name;
                                setValue("city", city)
                                break;
                            case "administrative_area_level_1":
                                state = respon.results[0].address_components[i].long_name;
                                setValue("state", state)
                                break;
                            case "country":
                                country = respon.results[0].address_components[i].long_name;
                                countryCode = respon.results[0].address_components[i].short_name;
                                setCountryCode(countryCode)
                                setValue("country", country)
                                break;
                            case "postal_code":
                                postCode = respon.results[0].address_components[i].long_name;
                                setValue("Zipcode", postCode)
                                break;
                            default:


                        }
                    }
                }
            })

        })
    };

    // console.log(isValid)
    const handleChange = (e) => {
        if (e.target.name === "country") {
            props.getstate(e.target.value);
        }
        if (e.target.name === "state") {
            props.getcity(e.target.value);
        }
        if (e.target.name === "network") {
            props.getSubNetwork(e.target.value);
        }

    };

    const renderButton = () => {
        if (formStep > 4) {
            return undefined;

        } else if (formStep === 3) {
            return (
                <div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-between p-3">
                        <Col lg={4} md={4} sm={12} xs={12} className="mt-2">
                            <button className="btn lgn-btn " type="button" onClick={goToPrevStep}>{t("Back")}</button>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12} className=" d-md-flex justify-content-end mt-2">
                            {prices && prices.length > 0 && props.loading ? (
                                <button className="btn lgn-btn" type="button" disabled>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    &nbsp;
                                    {t("Saving...")}
                                </button>
                            ) : (
                                <button className="btn lgn-btn" type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                                    {t("UPDATE CHARGING STATION")}
                                </button>
                            )}
                        </Col>
                    </div>
                </div>
            )
        } else if (formStep === 2) {
            return (
                <>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-between p-3">
                        <Col lg={5} md={6} sm={12} xs={12} className="mt-2">

                            {formStep > 1 ? <button className="btn lgn-btn " type="button" onClick={goToPrevStep}>{t("Back")}</button> : ''}
                        </Col>
                        <Col lg={7} md={6} sm={12} xs={12} className=" d-md-flex justify-content-end mt-2">

                            {chargersValid && connectorArray && connectorArray.length !== 0 &&
                                <button className="btn lgn-btn   " type="button" onClick={completeFormStep} disabled={!isValid || !chargersValid}>{t("PROCEED TO NEXT STEP")}</button>
                            }
                        </Col>
                    </div>

                </>

            )
        } else if (formStep === 1) {
            return (
                <>

                    <div className="d-md-flex justify-content-md-between p-3">
                        <Col lg={5} md={6} sm={12} xs={12} className="mt-2">

                            {formStep > 0 && props.fetchEvseID.statuscode !== 405 ? <button className="btn lgn-btn " type="button" onClick={goToPrevStep}>{t("Back")}</button> : ''}
                        </Col>
                        <Col lg={7} md={6} sm={12} xs={12} className=" d-md-flex justify-content-end mt-2">

                            {evseArray && evseArray.length !== 0 && props.fetchEvseID.statuscode !== 405 ?
                                <button className="btn lgn-btn  " type="button" onClick={completeFormStep} disabled={!isValid}>{t("PROCEED TO NEXT STEP")}</button> : ''
                            }
                        </Col>
                    </div >


                </>

            )
        } else {
            return (

                <div className="d-md-flex justify-content-md-end p-3">
                    <Col>

                        {formStep > 0 ? <button className="btn lgn-btn  ps-1" type="button" onClick={goToPrevStep}>{t("Back")}</button> : ''}
                    </Col>
                    <Col className='d-md-flex justify-content-end'>

                        {selectedAmenities && selectedAmenities.length > 0 && selectedAmenities.length > 0 && <button className="btn lgn-btn  " type="button" onClick={completeFormStep} disabled={!isValid}>{t("PROCEED TO NEXT STEP")}</button>}
                    </Col>
                </div>


            )
        }
    }


    return (
        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={12}>
                    <form autoComplete='off' onSubmit={e => e.preventDefault()}>
                        <Card className='pt-3'>
                            <div>
                                <div className="form-header d-flex mb-4">
                                    <span className="stepIndicator">{t("Update Location")}</span>
                                    <span className="stepIndicator">{t("Update Chargers")}</span>
                                    <span className="stepIndicator">{t("Update Connectors")}</span>
                                    <span className="stepIndicator">{t("Update Price")}</span>
                                    <span className="stepIndicator">{t("Launch Charging Station")}</span>
                                </div>

                            </div>
                            {formStep === 0 && (
                                <Card.Body>
                                    <div className="row g-2">
                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label>{t("Location Name")} <span className='mandatory-field'>*</span></label>
                                                <input type="text" className={`form-control gray-border`} name="location" id="floatingInputGridname" disabled placeholder={t("Location Name")} {...register("location")} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-2">
                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label>{t("Enter Address")}</label>
                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={handleAddressChange}
                                                    onSelect={handleSelect}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'Search Address ...',
                                                                    className: "location-search-input gray-border",
                                                                    style: { fontSize: 14, borderRadius: 2, height: 35 }
                                                                })}
                                                            />
                                                            <div className="autocomplete-dropdown-container">
                                                                {loading && <div>Loading...</div>}
                                                                {suggestions && suggestions.map(suggestion => {
                                                                    const style = suggestion.active
                                                                        ? { backgroundColor: 'var(--button-text-color)', cursor: 'pointer' }
                                                                        : { backgroundColor: 'var(--button-text-color) ', cursor: 'pointer' };
                                                                    return (
                                                                        <div className="input-suggestion"
                                                                            {...getSuggestionItemProps(suggestion, {

                                                                                style,
                                                                            })}
                                                                        >
                                                                            <span class="material-symbols-outlined"></span><span>{suggestion.description}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}


                                                </PlacesAutocomplete>
                                                {/* <input type="text" className={`form-control ${errors.address ? 'is-invalid' : ''}`} name="address" id="floatingInputGridname" placeholder="Enter the location address" {...register("address")} /> */}
                                                <div className="invalid-feedback">{errors.address?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-2">
                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label>{t("Country")} <span className='mandatory-field'>*</span></label>
                                                <input type="text" className={`form-control gray-border ${errors.country ? 'is-invalid' : ''}`} name="country" id="floatingInputzip" placeholder={t("Country")}  {...register("country")} />
                                                <div className="invalid-feedback">{errors.country?.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <div className="form-floating">
                                                    <label>{t("State")} <span className='mandatory-field'>*</span></label>
                                                    <input type="text" className={`form-control gray-border ${errors.state ? 'is-invalid' : ''}`} name="state" id="floatingInputzip" placeholder={t("State") + "/Province"}  {...register("state")} />
                                                    <div className="invalid-feedback">{errors.state?.message}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label>{t("City")} <span className='mandatory-field'>*</span></label>
                                                <input type="text" className={`form-control gray-border ${errors.city ? 'is-invalid' : ''}`} name="city" id="floatingInputzip" placeholder={"Enter " + t("City")}  {...register("city")} />
                                                <div className="invalid-feedback">{errors.city?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label>{t("Zipcode")} <span className='mandatory-field'>*</span></label>
                                                <input type="text" className={`form-control gray-border ${errors.Zipcode ? 'is-invalid' : ''}`} name="zipcode" id="floatingInputzip" placeholder={"Enter location " + t("Zipcode")}  {...register("Zipcode")} />
                                                <div className="invalid-feedback">{errors.Zipcode?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-2">
                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label>{t("Latitude")} <span className='mandatory-field'>*</span></label>
                                                <input type="number" className={`form-control gray-border ${errors.latitude ? 'is-invalid' : ''}`} name="latitude" id="floatingInputGridlat" placeholder={t("Latitude")} {...register("latitude")} />
                                                <div className="invalid-feedback">{errors.latitude?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label>{t("Longitude")} <span className='mandatory-field'>*</span></label>
                                                <input type="number" className={`form-control gray-border ${errors.longitude ? 'is-invalid' : ''}`} name="longitude" id="floatingInputlong" placeholder={t("Longitude")} {...register("longitude")} />
                                                <div className="invalid-feedback">{errors.longitude?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-2">
                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label> {t("Network Type")} <span className='mandatory-field'>*</span></label>
                                                <select className={`form-select  form-control ${errors.network ? 'is-invalid' : ''}`} name="network" id="floatingSelectGridnetwork" {...register("network")} onChange={(e) => handleChange(e)}>
                                                    <option value="">{t("Select Network Type")} </option>
                                                    {props.nlist && props.nlist.network && props.nlist.network.map((v, k) =>
                                                        <option key={k} value={v.code}>{t(v.name)}</option>
                                                    )}
                                                </select>
                                                <div className="invalid-feedback">{errors.network?.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label htmlFor='floatingSelectGridcategory'>{t("Sub-Network Category")} <span className='mandatory-field'>*</span></label>
                                                <select className={`form-select  form-control ${errors.category ? 'is-invalid' : ''}`} name="category" id="floatingSelectGridcategory" aria-label="select sub network type" {...register("category")}>
                                                    <option value={getValues("category")} hidden>{getValues("category")} </option>
                                                    {props.subnetlist && props.subnetlist.network_sub_type && props.subnetlist.network_sub_type.map((v, k) =>
                                                        <option key={k} value={v.name}>{t(v.name)}</option>
                                                    )}
                                                </select>
                                                <div className="invalid-feedback">{errors.category?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label>{t("Free Charging ?")} <span className='mandatory-field'>*</span></label>
                                                <select className={`form-select  form-control ${errors.free_charging ? 'is-invalid' : ''}`} name="free_charging" id="floatingSelectGridfree_charging" {...register("free_charging")}>
                                                    <option value="">{t("Is it free charging ?")} </option>
                                                    <option value={true}>{t("Yes")} </option>
                                                    <option value={false}>{t("No")} </option>
                                                </select>
                                                <div className="invalid-feedback">{errors.free_charging?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-2">
                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label>{t("Select Organisation")} <span className='mandatory-field'>*</span></label>
                                                <select className={`form-select  form-control ${errors.operator ? 'is-invalid' : ''}`} id="floatingSelectGridCity" name="operator" aria-label="Floating label select example" {...register("operator")}>
                                                    <option value={getValues("operator")} hidden>{getValues("operator")} </option>
                                                    {props.list && props.list.organisations && props.list.organisations.map((v, k) =>
                                                        <option key={k} value={v.name}>{t(v.name)}</option>
                                                    )}
                                                </select>
                                                <div className="invalid-feedback">{errors.operator?.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label>{t("Parking Type")} <span className='mandatory-field'>*</span></label>
                                                <select className={`form-select  form-control ${errors.parkingtype ? 'is-invalid' : ''}`} name="parkingtype" id="floatingSelectGrid" aria-label="Floating label select example" {...register("parkingtype")}>
                                                    <option value={""}>{t("Parking Type")}  </option>
                                                    {props.allParkingtype && props.allParkingtype.parkingtype && props.allParkingtype.parkingtype.map((v, k) =>
                                                        <option key={v.type} value={v.value}>{t(v.type)}</option>
                                                    )}
                                                </select>
                                                <div className="invalid-feedback">{errors.parkingtype?.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md p-2">
                                            <div className="form-floating">
                                                <label>{t("OCPI Enabled")} ?<span className='mandatory-field'>*</span></label>
                                                <select defaultValue={""} className={`form-select  form-control ${errors.ocpi ? 'is-invalid' : ''} `} id="floatingSelectGridocpi" name={`ocpi`} {...register(`ocpi`)} aria-label="Floating label select example">
                                                    <option value="">{t("Select OCPI")} </option>
                                                    <option value={true}>{t("Yes")} </option>
                                                    <option value={false} >{t("No")} </option>
                                                </select>
                                                <div className="invalid-feedback">{errors.ocpi?.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-md mt-2 ">
                                            <div className="form-floating">
                                                <label>{t("QR Code")} </label>
                                                <input className={`form-select qrcode `} id="floatingSelectGridqrcode" name={`qrcode`} {...register(`qrcode`)} aria-label="Floating label select example"></input>
                                                {/* <div className="invalid-feedback">{errors.qrcode?.message}</div> */}
                                            </div>
                                        </div>

                                    </div>
                                    <Row className="row g-2">
                                        <Col lg={3} md={4} xs={12} className="p-2">
                                            <p><b>{t("Select Amenities")} <span className='mandatory-field'>*</span></b></p>
                                            {props.amenities && props.amenities.amenity && props.amenities.amenity.map((item, index) => (
                                                <div className="evse_checkbox ml-3 form-check form-check-inline-block" key={item.name}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={"inlineCheckbox" + index}
                                                        value={item.name}
                                                        defaultChecked={data && data.amenities && data.amenities.includes(item.name)}
                                                        {...register("amenity")}
                                                        onChange={handleSelectChange}
                                                    />
                                                    <label className="form-check-label ml-5" htmlFor={"inlineCheckbox" + index}>
                                                        {t(item.name)}
                                                    </label>
                                                </div>
                                            ))}
                                        </Col>
                                        <Col lg={9} md={8} xs={12} className='mt-2'>
                                            <div className="form-floating">
                                                <label>{t("Description")}</label>
                                                <textarea
                                                    className={`form-control`}
                                                    placeholder={t("Description...")}
                                                    aria-label={t("Description")}
                                                    name="description"
                                                    id="floatingTextarea"
                                                    style={{ "height": "157px" }}
                                                    {...register("description")}></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>

                            )}

                        </Card>
                        {/* ------------Charger rendering------------- */}
                        {formStep === 1 && (
                            <div>

                                {fieldsList1.map((item, i) => (
                                    <Card key={i}>
                                        <Card.Body>
                                            <div className="row g-2 py-2">
                                                <div className="col-md">
                                                    <div className="form-floating ">
                                                        <label>{t("EVSE ID")}  <span className='mandatory-field'>*</span></label>
                                                        <input name={`evses[${i}].evse_id`} {...register(`evses.${i}.evse_id`)} type="text" id="floatingCPID" placeholder={t("EVSE ID")} className={`form-control  gray-border ${errors.evses?.[i]?.evse_id ? 'is-invalid' : ''}`}
                                                            onBlur={handleEvseIdBlur} />
                                                        <div className="invalid-feedback">{errors.evses?.[i]?.evse_id?.message}</div>
                                                        {props.fetchEvseID?.statuscode === 405 ? <p className='evse-name-warning'>{props.fetchEvseID && props.fetchEvseID.result}</p> : ''}
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Charger Name")} <span className='mandatory-field'>*</span></label>
                                                        <input className={`form-control gray-border ${errors.evses?.[i]?.charger_name ? 'is-invalid' : ''}`} name={`evses[${i}].charger_name`} {...register(`evses.${i}.charger_name`)} type="text" id="floatingCPID" placeholder={t("Charger Name")} />
                                                        <div className="invalid-feedback">{errors.evses?.[i]?.charger_name?.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("OCPP Version")} <span className='mandatory-field'>*</span></label>

                                                        <select className={`form-select  form-control ${errors.evses?.[i]?.ocpp ? 'is-invalid' : ''}`} name={`evses[${i}].ocpp`} {...register(`evses.${i}.ocpp`)} id="floatingSelectGrid" aria-label="Floating label select example">
                                                            <option value="">{t("Select OCPP Version")} </option>
                                                            <option value={"ocpp1.6"}>{t("OCPP 1.6")}</option>
                                                            <option value={"ocpp2"}>{t("OCPP 2.0.1")}</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.evses?.[i]?.ocpp?.message}</div>
                                                    </div>
                                                </div>
                                            </div>


                                        </Card.Body>
                                        <div className="d-grid gap-2 d-md-flex justify-content-end p-1">
                                            <button className="btn  btn-lg" type="button" onClick={(e) => { e.preventDefault(); removeList1(i); setTriggerEffect(prevState => !prevState) }}>
                                                <i className="far fa-trash-alt" style={{ "color": "red" }}>
                                                </i>
                                            </button>
                                        </div>

                                    </Card>

                                ))}

                                <Card.Footer>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-between">
                                        <button className="btn btn-dark btn-md" type="button" onClick={() => { appemdList1({ evse_id: '', charger_name: '', ocpp: '' }) }}> (+) {t("Add Chargers")}</button>
                                    </div>
                                </Card.Footer>
                            </div>
                        )}

                        {formStep === 2 && (
                            <div>

                                {fieldsList2.map((item, i) => (
                                    <Card key={i}>
                                        <Card.Body>

                                            {/* {!chargersValid && <p className='text-danger'>{t("Please add connectors for all chargers you have selected before proceeding.")}</p>} */}
                                            <div className="row g-2 p-1">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Charge Box ID")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${errors.connectors?.[i]?.evse_id ? 'is-invalid' : ''}`}
                                                            name={`connectors[${i}].evse_id`}
                                                            {...register(`connectors.${i}.evse_id`)}
                                                            id="floatingSelectGridCEVSE"
                                                        // onChange={handleChangeEvent}
                                                        >
                                                            <option value={""}>{t("Select Charge Box ID")} </option>
                                                            {evseArray && evseArray.map((v, k) =>
                                                                <option key={v.evse_id} value={v.evse_id}>{v.evse_id}</option>
                                                            )}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.connectors?.[i]?.evse_id?.message}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Connector ID ")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select  form-control ${errors.connectors?.[i]?.id ? 'is-invalid' : ''}`} name={`connectors[${i}].id`} {...register(`connectors.${i}.id`)} id="floatingSelectGridCid">
                                                            <option value={""}>{t("Select Connector ID")} </option>
                                                            <option value={"1"}>{t("Connector 1")}</option>
                                                            <option value={"2"}>{t("Connector 2")}</option>
                                                            <option value={"3"}>{t("Connector 3")}</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.connectors?.[i]?.id?.message}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{("Connector Type")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select  form-control ${errors.connectors?.[i]?.standard ? 'is-invalid' : ''}`} name={`connectors[${i}].standard`} {...register(`connectors.${i}.standard`)} id="floatingSelectGridCid">
                                                            <option value={""}>{t("Select Connector Type")} </option>
                                                            {props.allConnectortype && props.allConnectortype.connector && props.allConnectortype.connector.map((v, k) =>
                                                                <option key={v.type} value={v.value}>{v.type}</option>
                                                            )}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.connectors?.[i]?.standard?.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row g-2 p-1">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Connector Charging Speed ")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select  form-control ${errors.connectors?.[i]?.speed ? 'is-invalid' : ''}`} name={`connectors[${i}].speed`} {...register(`connectors.${i}.speed`)} id="floatingSelectGridCid">
                                                            <option value="">{t("Select Connector Charging Speed")} </option>
                                                            <option value="slow">{t("Slow")}</option>
                                                            <option value="fast">{t("Fast")}</option>
                                                            <option value="rapid">{t("Rapid")}</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.connectors?.[i]?.speed?.message}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Connector Energy Capacity")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${errors.connectors?.[i]?.format ? 'is-invalid' : ''}`} name={`connectors[${i}].format`} {...register(`connectors.${i}.format`)} id="floatingSelectGridCid">
                                                            <option value={""}>{t("Select Connector Energy Capacity")} </option>
                                                            {props.allPowerrange && props.allPowerrange.power && props.allPowerrange.power.map((v, k) =>
                                                                <option key={v.power} value={v.value}>{v.power}</option>
                                                            )}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.connectors?.[i]?.format?.message}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Connector Power Type ")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select  form-control ${errors.connectors?.[i]?.power_type ? 'is-invalid' : ''}`} name={`connectors[${i}].power_type`} {...register(`connectors.${i}.power_type`)} id="floatingSelectGridCid">
                                                            <option value="">{t("Select Connector Power Type")} </option>
                                                            <option value="AC">{t("AC")}</option>
                                                            <option value="DC">{t("DC")}</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.connectors?.[i]?.power_type?.message}</div>

                                                    </div>
                                                </div>


                                            </div>

                                        </Card.Body>
                                        <div className="d-grid gap-2 d-md-flex justify-content-end p-1">
                                            <button className="btn  btn-lg" type="button" onClick={(e) => { e.preventDefault(); removeList2(i); }}>
                                                <i className="far fa-trash-alt" style={{ "color": "red" }}></i>
                                            </button>
                                        </div>

                                    </Card>

                                ))}

                                <Card.Footer>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-between">
                                        <button className="btn btn-dark btn-md" type="button" onClick={() => appemdList2({ evse_id: '', id: '', standard: '', speed: '', format: '', power_type: '' })}> (+) {t("Add Connectors")}</button>
                                        {!chargersValid && <p className='text-danger'>{t("Please add connectors for all chargers you have selected before proceeding.")}</p>}
                                    </div>
                                </Card.Footer>

                            </div>
                        )}
                        {formStep === 3 && (

                            <Card>
                                {fieldsList3.map((item, i) => (
                                    <Card.Body key={i}>
                                        <div>
                                            <div className="row g-2 p-1">
                                                <div className="col-12">
                                                    <div className="form-floating">
                                                        <label>{t("Select Price List ")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${errors.prices?.[i]?.price ? 'is-invalid' : ''}`} name={`prices[${i}].price`} {...register(`prices[${i}].price`)} id="floatingSelectGridCid">
                                                            <option value={""}>{t("Select Price List ")}</option>
                                                            {props.fetchActiveTariffList && props.fetchActiveTariffList.data && props.fetchActiveTariffList.data.map((v, k) =>
                                                                <option key={v.id} value={v.name}>{v.name}</option>
                                                            )}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.prices?.[i]?.price?.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row g-2 p-1">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Publish ")} ? <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select  form-control ${errors.prices?.[i]?.publish ? 'is-invalid' : ''}`} name={`prices[${i}].publish`} {...register(`prices[${i}].publish`)} id="floatingSelectGrid" aria-label="Floating label select example">
                                                            <option value="">{t("Publish ")}</option>
                                                            <option value={true}>{t("Yes")}</option>
                                                            <option value={false}>{t("No")}</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.prices?.[i]?.publish?.message}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Status")} <span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select  form-control ${errors.prices?.[i]?.status ? 'is-invalid' : ''}`} id="floatingSelectGrid" name={`prices[${i}].status`} {...register(`prices[${i}].status`)} aria-label="Floating label select example">
                                                            <option value="">{t("Status")}</option>
                                                            {props.allChargerStatus && props.allChargerStatus.status && props.allChargerStatus.status?.map((v, k) =>
                                                                <option key={v.c_status} value={v.value}>{t(v.value)}</option>
                                                            )}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.prices?.[i]?.status?.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                ))}
                            </Card>
                        )}
                        {renderButton()}
                        <br />
                        <br />
                    </form>
                </Col>
            </Row>
        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.evse.isEvseLoading,
        subnetlist: state.evse.subnetwork,
        upstatus: state.evse.updateV2station,
        fetchActiveTariffList: state.tariff.fetchActiveTariffName,
        fetchEvseID: state.evse.fetchEvseId,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSubNetwork: (nt) => dispatch(fetSubNetworkAction(nt)),
        updateEvse: (data, stationid, selectedAmenities, countryCode) => dispatch(updateStationV2EvseAction(data, stationid, selectedAmenities, countryCode)),
        getActiveTariffList: () => dispatch(fetchActiveTariffList()),
        fetchAllEvseId: (evse_id) => dispatch(allEvseId(evse_id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateStationv2Component)

const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}