const initState = {
    isLoading: false,
    savepartner: '',
    fetchsinglepartner: '',
    allpartner: '',
    updatepartner: '',
    fetchLocationByPartyid: '',
    fetchSingleConsumedLocation: '',
    activateRoamingPartner: '',
    deactivateRoamingPartner: '',
    fetchPartnerTariff: '',
    allpartyid: '',
    fetchPartnerTariffByID: '',
    locationRefresh: '',
    tariffRefresh: '',
    generateToken:'',
}

const ocpiReducer = (state = initState, action) => {
    switch (action.type) {
        case 'OCPI_LOADING':
            return { ...state, isLoading: true }
        case 'SAVE_ROAMING_PARTNER':
            return { ...state, savepartner: action.payload, isLoading: false };
        case 'VIEW_ALL_ROAMING_PARTNER':
            return { ...state, allpartner: action.payload, isLoading: false };
        case 'VIEW_SINGLE_ROAMING_PARTNER':
            return { ...state, fetchsinglepartner: action.payload, isLoading: false };
        case 'UPDATE_ROAMING_PARTNER':
            return { ...state, updatepartner: action.payload, isLoading: false };
        case 'VIEW_LOCATION_BY_PARTY_ID':
            return { ...state, fetchLocationByPartyid: action.payload, isLoading: false };
        case 'FETCH_SINGLE_CONSUMED_LOCATION':
            return { ...state, fetchSingleConsumedLocation: action.payload, isLoading: false };
        case 'ACTIVATE_ROAMING_PARTNER':
            return { ...state, activateRoamingPartner: action.payload, isLoading: false };
        case 'DEACTIVATE_ROAMING_PARTNER':
            return { ...state, deactivateRoamingPartner: action.payload, isLoading: false };
        case 'FETCH_PARTNER_TARIFF':
            return { ...state, fetchAllPartnerTariff: action.payload, isLoading: false };
        case 'FETCH_PARTNER_ID':
            return { ...state, allpartyid: action.payload, isLoading: false };
        case 'FETCH_PARTNER_TARIFF_BY_ID':
            return { ...state, fetchPartnerTariffByID: action.payload, isLoading: false };
        case 'REFRESH_PARTNER_STATION_BY_ID':
            return { ...state, locationRefresh: action.payload, isLoading: false };
        case 'REFRESH_PARTNER_TARIFF_BY_ID':
            return { ...state, tariffRefresh: action.payload, isLoading: false };
            case 'GENERATE_TOKEN':
            return { ...state, generateToken: action.payload, isLoading: false };

        default:
            return state;
    }
}
export default ocpiReducer;