import { Container, Card, Row, Col } from "react-bootstrap";
import React, { useEffect } from "react";
import Table from "../../../reacttable/table";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PureBreadcrumbs from "../../../breadcrums";
import { useTranslation } from 'react-i18next';
import { viewSingleConsumedTariff } from "../../../../../store/actions/ocpiAction";

function ConsumedTariffView(props) {
    const { t } = useTranslation();
    const { id, party_id } = useParams();
    useEffect(() => {
        props.fetch(id, party_id)
    }, [id, party_id])
    const data = props.singleConsumedTariff &&
        props.singleConsumedTariff.data &&
        props.singleConsumedTariff.data[0] ? props.singleConsumedTariff.data[0] : {};

    const priceComponents = props.singleConsumedTariff && props.singleConsumedTariff.data[0]?.elements[0]?.price_components || [];
    return (
        <>
            <Container fluid>
                <PureBreadcrumbs />
                <br />
                <Card>
                    <div>
                        <h4 className='text-center p-3'>{t("Tariff Details")}</h4>
                    </div>
                    <Card.Body>
                        <div className="row g-2">
                            <div className="col-md p-2">
                                <div className="form-floating">
                                    <label htmlFor='floatingInputGridname'>{t("Party ID")}</label>
                                    <input type="text" className={`form-control gray-border `} name="party_id" id="floatingInputGridparty_id" placeholder={t("Party ID")} value={data?.party_id || 'Not Available'} disabled />
                                </div>
                            </div>
                            <div className="col-md p-2">
                                <div className="form-floating">
                                    <label htmlFor='floatingInputGridAddress'>{t("Country Code")}</label>
                                    <input type="text" className={`form-control gray-border `} name="country_code" id="floatingInputGridCC" placeholder={t("Country Code")} value={data?.country_code || 'Not Available'} disabled />
                                </div>
                            </div>
                            <div className="col-md p-2">
                                <div className="form-floating">
                                    <label htmlFor='floatingInputGridAddress'>{t("Currency")}</label>
                                    <input type="text" className={`form-control gray-border `} name="Currency" id="floatingInputGridCurrency" placeholder={t("Currency")} value={data?.currency || 'Not Available'} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <span style={{ color: "var(--text-primary)", fontWeight: "500", fontSize: 22 }}> {t("Price Component")} </span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">{t("Type")}</th>
                                                <th scope="col">{t("Price")}</th>
                                                <th scope="col">{t("Step Size")}</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(priceComponents) && priceComponents.map((tariff, index) =>
                                                <tr key={index}>
                                                    <td>{tariff.type}</td>
                                                    <td>{tariff.price}</td>
                                                    <td>{tariff.step_size}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )




}


const mapStateToProps = (state) => {
    return {
        singleConsumedTariff: state.ocpi.fetchPartnerTariffByID,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetch: (id, party_id) => dispatch(viewSingleConsumedTariff(id, party_id)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConsumedTariffView);

