import React, { useEffect, useState } from "react";
import PureBreadcrumbs from "../../../breadcrums";
import { Col, Container, Row } from "react-bootstrap";
import ConsumedStationList from "./consumedStationList";
import OcpiMap from "./consumeLocationMap";
import { connect } from "react-redux";
import { allRoamingPartnerPartnerID, fetchLocationbyPartyId, refreshPartnerStationAction } from "../../../../../store/actions/ocpiAction";
import { useTranslation } from "react-i18next";

function LocationConusmeCard(props) {
  const { t } = useTranslation();
  const [partnerName, setPartnerName] = useState("All");
  console.log("partnerNamepartnerName,partnerName",partnerName)
  useEffect(() => {
    props.allPartnerPartyId();
    props.consumedLocationData(partnerName);
  }, [partnerName])

  const data = props.fetchLocationbyParty && props.fetchLocationbyParty.data;

  const handleChange = (e) => {
    let partnerid = e.target.value
    setPartnerName(partnerid)
  };

  const handleAddClick = () => {
    props.partnerRefreshLocation(partnerName)
};

  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />
      <Row>
        <label className="ml-3 d-flex align-items-center" htmlFor="partnerNameDropdown">{t("Partner name")} :</label>
        <Col lg={4}>
          <select className='form-control form-select' id="partnerNameDropdown" onChange={handleChange}>
            <option value={"All"}>{t("All")}</option>
            {props.partyidlist && props.partyidlist.data && props.partyidlist.data.map((item) =>
              <option key={item.id} value={item.party_id}>{item.partner_name}</option>
            )}
          </select>
        </Col>
        {partnerName !== "All" ? <Col className="col-lg-6 col-sm-12 text-right">
        <a className="refresh" style={{ fontSize: "13px", color: "var(--button-color)", fontWeight: "bold", cursor: "pointer" }}  onClick={handleAddClick} >Refresh location for : {partnerName} &nbsp;<img style={{ height: "16px" }} src={process.env.PUBLIC_URL + '/images/insight/refresh.svg'} alt="" /></a>
        </Col>: ''}
        
      </Row>
      <OcpiMap {...props} />
      <ConsumedStationList {...props} data={data} />
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    loading: state.ocpi.isLoading,
    partyidlist: state.ocpi.allpartyid,
    fetchLocationbyParty: state.ocpi.fetchLocationByPartyid,
    prtnerLocationRefresh : state.ocpi.locationRefresh,
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    allPartnerPartyId: () => dispatch(allRoamingPartnerPartnerID()),
    consumedLocationData: (partner_name) => dispatch(fetchLocationbyPartyId(partner_name)),
    partnerRefreshLocation:(partner_id) => dispatch(refreshPartnerStationAction(partner_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationConusmeCard);
