import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

const MyDocument = (props) => {
    const { t } = useTranslation();

    const generatePDF = () => {
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";
        const marginLeft = 40;
        const marginTop = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);

        // Define headers with formatted names
        const headers = [
            "Rfid", "Commission", "Currency", "Status",
            "Amount Based", "Amount", "Free Charging", "Kwh Based", "Kwh", "Kwh Amount",
            "Time Based", "Time", "Time Amount", "Type", "Sub Duration", "Station", "Offline Payment"
        ];

        // Map data from props to match headers
        const data = props.data.map(obj => {
            return [
                obj.rfid,
                obj.commission,
                obj.currency,
                obj.status,
                obj.amount_based ? "Yes" : "No",
                obj.amount,
                obj.free_charging ? "Yes" : "No",
                obj.kwh_based ? "Yes" : "No",
                obj.kwh,
                obj.kwh_amount,
                obj.time_based ? "Yes" : "No",
                obj.time,
                obj.time_amount,
                obj.type,
                obj.sub_duration,
                obj.station,
                obj.offline_payment ? "Yes" : "No"
            ];
        });


        let content = {
            startY: 50,
            head: [headers],
            body: data
        };

        doc.text("RFID Report", marginLeft, 40);
        doc.autoTable(content);
        doc.save("Rfid_Report.pdf");
    };

    const handleButtonClick = () => {
        generatePDF();
    };

    return (
        <div>
            <button className='pdfbutton' onClick={handleButtonClick} type="button">{t("Generate RFID PDF")}</button>
        </div>
    );
};

export default MyDocument;
